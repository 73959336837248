import React from "react"

import Background from "../components/background"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/bootstrap/container"
import PageTitle from "../components/page-title"
import PrimaryButton from "../components/primary-button"
import WazokuLogoImage from "../components/wazoku-logo-image"
import PartnerCard from "../components/partner-card"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const EnterPage = () => (
  <Background>
    <Layout>
      <SEO title="Enter" />
      <Container>
        <PageTitle text="Enter" />
        <div style={{
          textAlign: `center`,
          backgroundColor: `rgba(0,0,0,0.6)`
        }}>
          <div style={{
            maxWidth: `34rem`,
            margin: `auto`,
            padding: `5rem 2rem 1rem 2rem`
          }}>
            <span style={{
              display: `inline`,
              backgroundColor: `#d23464`,
              padding: `.5rem 0`,
              lineHeight: `3.4rem`,
              boxShadow: `.5rem 0 0 #d23464, -.5rem 0 0 #d23464`,
              textTransform: `uppercase`,
              color: `#fff`,
              fontFamily: `Din Pro`,
              fontWeight: `700`,
              fontSize: `1.6rem`
            }}>Key Dates</span>
          </div>
          <Container>
            <div className="row" style={{
                  color: `#fff`,
                  padding: `2rem`
                }}>
              <div className="col-md">
                <h4 style={{
                  textTransform: `uppercase`,
                  fontSize: `1.2rem`
                }}>Entry Deadline</h4>
                <p>Closed on 10/03/2020</p>
              </div>
              <div className="col-md">
                <h4 style={{
                  textTransform: `uppercase`,
                  fontSize: `1.2rem`
                }}>Shortlist Announced</h4>
                <p>15/06/2020</p>
              </div>
              <div className="col-md">
                <h4 style={{
                  textTransform: `uppercase`,
                  fontSize: `1.2rem`
                }}>Awards Date</h4>
                <p>26/11/2020</p>
              </div>
            </div>
          </Container>
        </div>
        <div style={{
          fontFamily: `Din Pro`,
          fontWeight: `400`,
          fontSize: `1rem`,
          color: `#fff`,
          background: `linear-gradient(90deg, rgba(210,52,100,.9) 0%, rgba(251,146,1,.9) 100%)`
        }}>
          <Container>
            <div className="row" style={{ padding: `2rem`}}>
              <div className="col-lg">
                <h5>A Proud Partnership</h5>
                <p>For Smarter Working Live, we are proud to partner with Wazoku, drivers of digital innovation, to bring you a platform to digitally submit your awards nominations.</p>
                
                <h5>Registration</h5>
                <p>To enter the awards, you will need to create an account. Once you click onto ‘Enter Your Nomination’, you will be able to register for an account or login if you have already created an account. You will then be able to manage, edit or revise your entry/entries for any of the categories.</p>

                <h5>Nomination Privacy</h5>
                <p>All details entered in your submission will remain private and will not be disclosed. Your Project Name and Organisation Name will become public should your nomination progress to the shortlist.</p>
              </div>
              <div className="col-lg">
                <PartnerCard title="Nomination Partner" width="12rem" style={{ float: `right`, marginLeft: `1rem`, marginBottom: `1rem` }}>
                  <OutboundLink href="https://www.wazoku.com" target="_blank" rel="noopener noreferrer"><WazokuLogoImage /></OutboundLink>
                </PartnerCard>

                <h5>Managing Your Entries</h5>
                <p>You can enter as many categories as you like by completing the entry form for the category/categories that you wish to enter. If you wish to enter multiple categories, please ensure that it is relevant to the category you are entering.</p>
                <p>You can create your nomination in draft form, allowing you to perfect your entry/entries before submission.</p>
                <p>If you have published your entry/entries, you will still be able to edit and manage your submission, however, all entry modifications must be made before the closing date.</p>

                <span className="mr-4"><PrimaryButton url="https://smarterworkinglive.wazoku.com" text="Login" /></span>
                <span className="mr-4"><PrimaryButton url="https://smarterworkinglive.wazoku.com/#/register" text="Register" /></span>
              </div>
            </div>
          </Container>
        </div>



        <div style={{
          fontFamily: `Din Pro`,
          fontWeight: `400`,
          fontSize: `1rem`,
          color: `#fff`,
          background: `linear-gradient(90deg, rgba(48,196,191,1) 0%, rgba(180,1,180,1) 100%)`
        }}>
          <Container>
            <div className="row" style={{ padding: `2rem`}}>
              <div className="col-lg">
                <h5>An Alternative Option</h5>
                <p>If you would prefer to submit your nomination by email, please download a copy of our entry form and send a copy of your completed form along with any addtional material to entries@smarterworkinglive.com.</p>

              </div>
              <div className="col-lg">
                <h5>Questions</h5>
                <p>If you have any questions, please email us on <a href="mailto:entries@smarterworkinglive.com" style={{color: `#fff`}}>entries@smarterworkinglive.com</a> or call <a href="tel:+441616973438" style={{color: `#fff`}}>0161 697 3438</a>.</p>
                <a href={`/downloads/Smarter_Working_Live_2020_Entry_Form.docx`} className="btn btn-primary" style={{
                  backgroundColor: `#fff`,
                  borderColor: `#fff`,
                  color: `#d23464`,
                  textTransform: `uppercase`,
                  borderRadius: `0`,
                  padding: `.6rem 1rem`,
                  fontWeight: `600`
                }} download>Download Entry Form</a>
              </div>
            </div>
          </Container>
        </div>
        <div style={{marginTop: `100px`}}></div>
      </Container>
    </Layout>
  </Background>
)

export default EnterPage
